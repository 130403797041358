<template>
	<div class="container" v-if="$asyncComputed.project.state === 'success'">
		<h3>New configuration</h3>

		<form @submit.prevent="save">
			<div class="form-group">
				<h5>Name</h5>
				<input v-model="model.name" type="text" class="form-control" placeholder="MATH #1">
			</div>

			<div class="form-group">
				<h5>Notes</h5>
				<textarea v-model="model.notes" type="text" class="form-control"
						  placeholder="Freespins, wild, scatter and variant notes">
				</textarea>
			</div>

			<div class="form-group">

				<div class="form-check">
					<input class="mr-2" type="radio" value="null" v-model="model.cloneFrom"> Start from scratch
				</div>
				<hr>
				<h5>Clone from {{project.Name}}</h5>
				<div class="form-check d-flex align-items-center" v-for="configuration in configurations">
					<input class="mr-2" :id="`config-${configuration.Uuid}`" type="radio" :value="configuration.Uuid"
						   v-model="model.cloneFrom">
					<label :for="`config-${configuration.Uuid}`">{{configuration.Name}}<br/><small class="text-light"
																								   v-rel-date="configuration.UpdatedAt"></small></label>
				</div>

			</div>

			<button class="btn btn-primary px-4" type="button" :disabled="loading">
				<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" v-if="loading"></span>
				<button type="submit" class="btn btn-primary px-4">CREATE</button>
			</button>
		</form>
	</div>
</template>

<script>
	import api from '@/api'

	export default {
		data() {
			return {
				loading: false,
				model: {
					name: "",
					notes: "",
					cloneFrom: null
				}
			}
		},
		methods: {
			async save() {
				this.loading = true
				let configuration = await api.Projects.saveNewProjectConfiguration(this.$route.params.uuid, this.model)

				this.$store.dispatch('Projects/load')

				await this.$router.push({
					name: 'projects-editor-uuid-cuuid-settings',
					params: {
						uuid: this.$route.params.uuid,
						cuuid: configuration.Uuid
					}
				});

				this.loading = true
			}
		},
		asyncComputed: {
			configurations() {
				return api.Projects.getProjectConfigurations(this.$route.params.uuid)
			},
			project() {
				return api.Projects.getProject(this.$route.params.uuid)
			}
		},
		computed: {
			projects() {
				return this.$store.getters['Projects/list']
			}
		}
	}
</script>
